<template>
  <div class="pastBox">
    <div ref="pastCharts" id="pastCharts"></div>
  </div>
</template>

<script>
  export default {
    name: 'pastCharts',
    data() {
      return {

      }
    },
    props:['msg'],
    created(){
      // console.log(this.msg);
    },
    mounted() {
      this.myCharts();
    },
    methods: {
      myCharts() {
        let bgColor = 'none';
        let title = '人像卡口';
        let colorList = ['#d22828', '#18b25a'];
        let echartData = [{
            name: '多',
            value: this.msg.dDuoBili,
            label:{
              color:'#d22828',
            }
          },
          {
            name: '空',
            value: this.msg.dKongBili,
            label:{
              color:'#18b25a',
            }
          },
        ];

        let formatNumber = function (num) {
          let reg = /(?=(\B)(\d{3})+$)/g;
          return num.toString().replace(reg, ',');
        };
        let total = echartData.reduce((a, b) => {
          return a + b.value * 1;
        }, 0);
        var pastCharts = this.$echarts.init(this.$refs.pastCharts);
        var option = {
          grid: {
            left: "3%",
            right: "3%",
            top: "10%",
            bottom: "10%",
            containLabel: true,
          },
          // backgroundColor: bgColor,
          color: colorList,
          // tooltip: {
          //     trigger: 'item'
          // },
          title: [{
            // text: "{name|" + title + "}\n{val|" + formatNumber(total) + "}",
            text: '{name|' + this.msg.strName + '}',
            left: "39%",
            top: "45%",
            textStyle: {
              rich: {
                fontSize: 22,
                color: '#000',
                name: {
                  fontSize: 22,
                  color: '#000',
                  padding: [10, 0],
                },
              },
            },
          }, ],
          // legend: {
          //   orient: 'vertical',
          //   // icon: 'rect',
          //   itemWidth: 12,
          //   itemHeight: 12,
          //   top: "50%",
          //   right: "15%",
          //   selectedMode:false,
          //   textStyle: {
          //     fontSize: 16,
          //     color: '#000000',
          //   },
          // },
          series: [{
            type: 'pie',
            radius: ['75%', '69%'],
            center: ['50%', '55%'],
            // color: colorList,
            data: echartData.map((item, index)=> {
              item.label = { 
                color: colorList[index],
              }
              return item;
            }),
            hoverAnimation: false,
            itemStyle: {
              borderRadius: 60,
              borderWidth: 10,
              color:(params)=> {
                let index = params.dataIndex;
                return colorList[index];
              },
            },
            labelLine: {
              normal: {
                show: false,
                length: 5,
                length2: 160,
                lineStyle: {
                  color: '#e6e6e6',
                },
              },
            },
            label: {
              normal: {
                formatter: (params) => {
                  return '{value|' + formatNumber(params.value) + '%}';
                },
                padding: [0, -130, 25, -130],
                rich: {
                  icon: {
                    fontSize: '16',
                  },
                  value: {
                    fontSize: '16',
                    color: '#000',
                  },
                },
              },
            },
          }, ],
        };
        pastCharts.setOption(option);
      },
    }
  }
</script>

<style lang="less" scoped>
  .pastBox {
    width: 100%;
    height: 100%;

    #pastCharts {
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }
</style>