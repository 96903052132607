<template>
  <div class="dcBox">
    <div class="dcBoxTop">
      <div class="topTitle">
        <p>过去24小时开仓</p>
        <p>全部开仓</p>
      </div>
      <div class="topCenter">
        <div class="chartBox">
          <div>
            <past-charts-vue v-if="echartsData_24H.length>0" :msg="echartsData_24H[0]"></past-charts-vue>
            <past-charts-vue v-if="echartsData_24H.length>0" :msg="echartsData_24H[1]"></past-charts-vue>
          </div>
          <p>
            <span>更新时间：</span>
            <span v-if="echartsData_24H.length>0">{{echartsData_24H[0].strLastUpdate}}</span>
          </p>
          <ul>
            <li>
              <span></span>
              <span>多头</span>
            </li>
            <li>
              <span></span>
              <span>空头</span>
            </li>
          </ul>
        </div>
        <span class="fgLine"></span>
        <div class="chartBox">
          <div>
            <past-charts-vue v-if="echartsData_total.length>0" :msg="echartsData_total[0]"></past-charts-vue>
            <past-charts-vue v-if="echartsData_total.length>0" :msg="echartsData_total[1]"></past-charts-vue>
          </div>
          <p>
            <span>更新时间：</span>
            <span v-if="echartsData_total.length>0">{{echartsData_total[0].strLastUpdate}}</span>
          </p>
          <ul>
            <li>
              <span></span>
              <span>多头</span>
            </li>
            <li>
              <span></span>
              <span>空头</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="dcBoxTop_wap">
      <div class="24hBox wapEcharts">
        <ul>
          <li>过去24小时开仓</li>
          <li>
            <span></span>
            <span>多头</span>
          </li>
          <li>
            <span></span>
            <span>空头</span>
          </li>
        </ul>
        <div class="borderBox">
          <past-charts-wap-vue class="past-chart" v-if="echartsData_24H.length>0" :msg="echartsData_24H[0]"></past-charts-wap-vue>
          <past-charts-wap-vue class="past-chart" v-if="echartsData_24H.length>0" :msg="echartsData_24H[1]"></past-charts-wap-vue>
        </div>
        <!-- <p>
          <span>更新时间：</span>
          <span v-if="echartsData_24H.length>0">{{echartsData_24H[0].strLastUpdate}}</span>
        </p> -->
      </div>
      <div class="allBox wapEcharts">
        <ul>
          <li>全部开仓</li>
          <li>
            <span></span>
            <span>多头</span>
          </li>
          <li>
            <span></span>
            <span>空头</span>
          </li>
        </ul>
        <div class="borderBox">
          <past-charts-wap-vue class="past-chart" v-if="echartsData_total.length>0" :msg="echartsData_total[0]"></past-charts-wap-vue>
          <past-charts-wap-vue class="past-chart" v-if="echartsData_total.length>0" :msg="echartsData_total[1]"></past-charts-wap-vue>
        </div>
        <p>
          <span>更新时间：</span>
          <span v-if="echartsData_total.length>0">{{echartsData_total[0].strLastUpdate}}</span>
        </p>
      </div>
    </div>
    <div class="dcBoxBottom">
      <img src="../../../static/index/data2.png" alt="">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{textAlign: 'center',backgroundColor:'#eef2fe',color:'#343436',height:'60px'}"
        :row-style="{height:'60px'}"
        :cell-style="{ textAlign: 'center',color:'#959595'}">
        <el-table-column
          prop="strLogin"
          label="用户账号">
        </el-table-column>
        <el-table-column
          prop="strTime"
          label="时间">
        </el-table-column>
        <el-table-column
          prop="dOpenPrice"
          label="报价">
        </el-table-column>
        <el-table-column
          prop="dLastPrice"
          label="成交价格">
        </el-table-column>
        <el-table-column
          prop="strOpenClose"
          label="操作">
          <template slot-scope="scope">
            <span v-if="scope.row.strOpenClose == 'open'">建仓</span>
            <span v-if="scope.row.strOpenClose == 'close'">平仓</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="strSymbol"
          label="产品">
        </el-table-column>
        <el-table-column
          prop="strBuySell"
          label="买/卖">
          <template slot-scope="scope">
            <span v-if="scope.row.strBuySell == 'buy' ">买</span>
            <span v-if="scope.row.strBuySell == 'sell' ">卖</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dVolume"
          label="手数">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PastChartsVue from './echarts/pastCharts.vue';
import PastChartsWapVue from './echarts/pastChartsWap.vue';
import {datacenterInit,datacenterGoldSilv,DataCenter_GoldSilverPos_Total} from '../../../api/info';
export default {
  name:'dataCenter',
  components:{
    PastChartsVue,
    PastChartsWapVue,
  },
  data() {
    return {
      // 表格数据
      tableData: [],
      // echarts数据
      echartsData_24H:[],
      echartsData_total:[],
    }
  },
  created(){
    // 获取表格数据
    datacenterInit({size:7,days:7}).then(res=>{
      if(res.IsSuccess == true){
        this.tableData = res.Message;
      }
    }).catch(err=>{
      console.log(err);
    })
    // 获取echarts-24H数据
    datacenterGoldSilv().then(res=>{
      if(res.IsSuccess == true){
        this.echartsData_24H = res.Message;
      }
    }).catch(err=>{
      console.log(err);
    })
    // 获取echarts-total数据
    DataCenter_GoldSilverPos_Total().then(res=>{
      if(res.IsSuccess == true){
        this.echartsData_total = res.Message;
      }
    }).catch(err=>{
      console.log(err);
    })
  },
  methods:{

  }
}
</script>

<style lang="less" scoped>
  .dcBox{
    max-width: 1216px;
    height: auto;
    margin: 0 auto;
    .dcBoxTop{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .topTitle{
        width: 100%;
        height: 80px;
        background: url('../../../static/index/data1.png') no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        p{
          width: 50%;
          text-align: center;
          color: #313234;
          font-size: 20px;
        }
      }
      img{
        width: 100%;
        height: auto;
      }
      .topCenter{
        width: 99.8%;
        height: 255px;
        border: 1px solid #f3eff0;
        border-top: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .chartBox{
          width: 49.5%;
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          position: relative;
          div{
            width: 100%;
            height: 200px;
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
          p{
            display: flex;
            justify-content: center;
            align-items: center;
            span{
              color: #9a9a9a;
              font-size: 13px;
            }
          }
          ul{
            position: absolute;
            bottom: 15px;
            right: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            li{
              display: flex;
              justify-content: center;
              align-items: center;
              span{
                font-size: 16px;
                color: #999999;
              }
              span:nth-child(1){
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #d22828;
                margin-right: 8px;
              }
            }
            li:nth-child(2){
              margin-top: 5px;
              span:nth-child(1){
                background-color: #18b25a;
              }
            }
          }
        }
        .fgLine{
          width: 1px;
          height: 153px;
          background-color: #d9d9d9;
        }
      }
    }
    .dcBoxTop_wap{
      display: none;
    }
    .dcBoxBottom{
      width: 100%;
      margin-top: 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media screen and (max-width: 500px) {
    .dcBox{
      width: 100%;
      .dcBoxTop{
        display: none;
        .topTitle{
          flex-direction: column;
        }
        .topCenter{
          flex-direction: column;
          height: auto;
          .chartBox{
            width: 100%;
          }
          .fgLine{
            display: none;
          }
        }
      }
      .dcBoxTop_wap{
        display: block;
        width: 100%;
        .wapEcharts{
          width: 97%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          ul{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../../../static/index/wapEcharts.png') no-repeat;
            background-size: cover;
            box-sizing: border-box;
            padding: 12px 0;
            li:nth-child(2){
              margin: 0 10px 0 80px;
              span:nth-child(1){
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #d22828;
                border-radius: 50%;
              }
              span{
                color: #9a9a9a;
                font-size: 14px;
                margin-left: 5px;
              }
            }
            li:nth-child(3){
              span:nth-child(1){
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: #18b25a;
                border-radius: 50%;
              }
              span{
                color: #9a9a9a;
                font-size: 14px;
                margin-left: 5px;
              }
            }
          }
          .borderBox{
            width: 100%;
            display: flex;
            margin: 0 auto;
            border: 1px solid #f3eff0;
            box-sizing: border-box;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            .past-chart{
              width: 48%;
              height: 150px;
            }
          }
          p{
            width: 100%;
            text-align: center;
            margin-top: 30px;
            span{
              font-size: 14px;
              color: #9a9a9a;
            }
          }
        }
        .allBox{
          margin-top: 30px;
        }
      }
      .dcBoxBottom{
        flex-direction: column;
        margin-top: 30px;
        img{
          display: none;
        }
        /deep/.el-table{
          .el-table__header{
            height: 55px;
          }
        }
      }
    }
  }
</style>